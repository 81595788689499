<script setup lang="ts">
  import { useDisplay } from "vuetify";

  const layoutCustomProps = useAttrs();
  const heading = layoutCustomProps.heading;
  const canBack = layoutCustomProps.back ?? false;
  const backTo = layoutCustomProps.backto;

  const onClickBack = () => {
    if (backTo) {
      navigateTo(backTo);
      return;
    }
    useRouter().back();
  };

  // レスポンシブ対応
  const { name } = useDisplay();
  const headingFontSize = computed<string>(() => (name.value === "xs" ? "18px" : "24px"));
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="mx-auto" style="width: 90%">
        <v-icon v-if="canBack" data-testid="back-button" size="40px" class="back" @click="onClickBack"
          >$mdiChevronLeft</v-icon
        >
        <div class="d-flex flex-row justify-center">
          <NuxtLink to="/">
            <AtomsFincsLogoSvg height="30" class="mt-8" />
          </NuxtLink>
        </div>
      </div>
      <v-card color="monochrome-04" class="mx-auto mt-8 mb-8 d-flex flex-column align-center content-width" width="90%">
        <!-- 画面見出し -->
        <v-row v-if="heading" justify="center" width="100%" class="my-4 inner-width">
          <v-col align="center">
            <div class="my-4 heading">{{ heading }}</div>
          </v-col>
        </v-row>

        <div class="inner-width">
          <slot />
        </div>
      </v-card>
      <div class="mx-auto" style="width: 90%">
        <slot name="bottom-content" />
      </div>
    </NuxtLayout>
  </div>
</template>

<style lang="scss" scoped>
  .back {
    position: absolute;
    top: 48px;
  }
  .heading {
    font-size: v-bind("headingFontSize");
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.06em;
    text-align: center;
  }
  .content-width {
    max-width: 906px;
  }
  .inner-width {
    width: 100%;
    max-width: 720px;
  }
</style>
